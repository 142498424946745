<template>
  <div>
    <b-card v-if="!instance">
      <div class="mx-auto d-flex justify-content-center mb-3">
        <b-spinner small type="grow"></b-spinner>
      </div>
    </b-card>

    <b-card :class="status_class" header-border-variant="white" no-body @click="clicked_instance">
      <div class="instance-card-content">
        <h4 class="text-center">{{ instance.instance_id.toUpperCase() }}</h4>
        
        <div class="info-row">
          <p>V: {{ instance.version }}</p>
          <p>{{ visual_time_since }}</p>
        </div>

        <div v-if="instance.stats" class="stats-row">
          <div class="stat-item" v-b-tooltip.hover title="Users">
            <i class="mdi mdi-account" aria-hidden="true"></i>
            <span>{{ instance.stats.users }}</span>
          </div>
          <div class="stat-item" v-b-tooltip.hover title="Members">
            <i class="mdi mdi-account-group" aria-hidden="true"></i>
            <span>{{ instance.stats.members }}</span>
          </div>
        </div>
      </div>
    </b-card>
  </div>
    
</template>

<script>
import axios from 'axios';
import dayjs from 'dayjs';
import { mapGetters } from 'vuex';


export default {
  name: 'DashboardInstanceStatus',
  components: {

  },
  props: ['instance'],
  data() {
    return {
      refresh: 0,
      interval_id: null
    };
  },
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId','isAuthenticated','initComplete','user_prefs']),
    status_class() {
      this.refresh;

      const seconds_since = this.calculate_seconds_since();
      if (seconds_since === null) {
        return 'bg-light';
      }

      if (seconds_since <= 60 * 10) {
        return 'bg-success';
      } else if (seconds_since < 60 * 20) {
        return 'bg-warning';
      } else {
        return 'bg-danger';
      }
    },
    visual_time_since() {
      const minutes_since = this.calculate_minutes_since();
      if (minutes_since === null) {
        return '...'
      }

      if (minutes_since < 60) {
        return `${minutes_since} minutes`;
      } else {
        const hours = Math.floor(minutes_since / 60);
        const minutes = minutes_since % 60;
        return `${hours} hours, ${minutes} minutes`;
      }
    }
  },
  mounted() {

    if (this.interval_id) {
      return;
    }

    this.interval_id = setInterval(() => {
      this.refresh++;
    }, 1000);
  },
  watch: {
    currentCompanyId() {
    
    },
    currentPeriodId(newValue, oldValue) {
    
    }
  },
  methods: {
    clicked_instance() {
      window.open('https://' + this.instance.domain + '/ml-login', '_blank');
    },
    calculate_seconds_since() {
      
      if (!this.instance || !dayjs(this.instance.last_polled_at).isValid()) {
        return null
      }

      const now = dayjs();
      const created = dayjs(this.instance.last_polled_at);
      return now.diff(created, 'seconds');
    },
    calculate_minutes_since() {
      if (!this.instance || !dayjs(this.instance.last_polled_at).isValid()) {
        return null
      }

      const now = dayjs();
      const created = dayjs(this.instance.last_polled_at);
      return now.diff(created, 'minutes');
    },
  }
};
</script>

<style lang="scss" scoped>


.instance-card-content h4 {

  font-size: 1.25rem;
  margin-bottom: 2px;

  /* Add these styles to apply ellipsis */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.info-row {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin: 5px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.info-row p {
  margin: 0;               /* Remove default margins from <p> */
}


.instance-card-content:hover {
  cursor: pointer;
}

.instance-card-content {
  
  padding: 4px;
  text-align: center;
  height: 90px;
}

.stats-row {
  display: flex;
  justify-content: space-around;
  margin-top: 2px;
}

.stat-item {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.stat-item i {
  font-size: 24px;
  margin-right: 5px;
}
  
</style>
